// script.js
//$(document).ready(function() {
//    $(window).on("scroll", () => {
//        if ($(window).scrollTop()) {
//            $('nav').addClass('bg-dark');
//        } else {
//            console.log('scrolling down');
//            $('nav').removeClass('bg-dark');
//        }
//    })
//
